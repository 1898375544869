// Default Function
export default function ServerError() {
  return (
    <main className="grid h-svh place-items-center px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
            <p className="text-base font-semibold text-red-600">oops !!!</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">Broken Pipes</h1>
            <p className="mt-6 text-base leading-7">Could not establish connection with server</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                    href="/"
                    className="rounded-md bg-secondary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                >
                Try again
                </a>
                <a
                href="/"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                Contact support
                </a>
          </div>
        </div>
    </main>
  )
}
